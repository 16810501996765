import ms from 'ms';
import { useEffect, useRef, useState } from 'react';
import Socket from 'services/Socket';
import initializeSockets from 'utils/initializeSockets';

const useConnecting = () => {
  const initialized = Socket.initialized();
  const [connecting, setConnecting] = useState(!Socket.isConnected());
  const waited = useRef(0);

  useEffect(() => {
    if (!initialized) {
      initializeSockets(); // No props needed anymore
    }
    if (connecting) {
      const interval = setInterval(() => {
        waited.current += 500;
        const socketConnected = Socket.isConnected();
        if (socketConnected) {
          clearInterval(interval);
          setConnecting(false);
        } else if (waited.current > ms('4 seconds')) {
          waited.current = 0;
          Socket.connect();
        }
      }, 500);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [connecting, initialized]);

  return connecting;
};

export default useConnecting;
