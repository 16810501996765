import debounce from 'lodash/debounce';
import Socket from 'services/Socket';
import getMoment from 'utils/getMoment';
import store from 'store';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import channels from 'store/app/entities/channels/action';
import companies from 'store/app/entities/companies/action';
import employeeImports from 'store/app/entities/employeeImports/action';
import employees from 'store/app/entities/employees/action';
import messages from 'store/app/entities/messages/action';
import users from 'store/app/entities/users/action';
import visibility from 'store/app/ui/focus/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import populateChannels from 'store/selectors/populateChannels';

// Fire immediately and every 2s while typing
const debounceTypingActive = debounce(
  (actions, channel, user, status) => {
    actions.users.typing(channel, user, status);
  },
  2000,
  { leading: true, maxWait: 2000, trailing: false },
);

// Fire after 2,5s of inactivity
const debounceTypingInactive = debounce(
  (actions, channel, user, status) => {
    actions.users.typing(channel, user, status);
  },
  2500,
  { leading: false, trailing: true },
);

const initializeSockets = () => {
  if (Socket.initialized()) {
    return Socket;
  }

  // Get state and dispatch directly from store
  const state = store.getState();
  const { dispatch } = store;

  // Create props object here instead of receiving it
  const props = {
    actions: {
      visibility: bindActionCreators(visibility, dispatch),
      auth: bindActionCreators(auth, dispatch),
      users: bindActionCreators(users, dispatch),
      employeeImports: bindActionCreators(employeeImports, dispatch),
      employees: bindActionCreators(employees, dispatch),
      companies: bindActionCreators(companies, dispatch),
      messages: bindActionCreators(messages, dispatch),
      channels: bindActionCreators(channels, dispatch),
      bulkMessagings: bindActionCreators(bulkMessagings, dispatch),
    },
    channels: populateChannels(state),
    authEmployee: authEmployee(state),
    users: state.users,
    authCompany: authCompany(state),
  };

  const fixOneToOneChannelId = rawMessage => {
    if (rawMessage.channel === props.authEmployee?.user?._id) {
      return rawMessage.from._id;
    }
    return rawMessage.channel;
  };

  Socket.connect();

  // Use props.actions and props.authEmployee/authCompany in all the event handlers
  Socket.on('connect', () => {
    props.actions.visibility.onConnect();
  });

  Socket.on('newMessage', ({ message: rawMessage }) => {
    const fixedRawMessage = {
      ...rawMessage,
      channel: fixOneToOneChannelId(rawMessage),
      createdAt: getMoment(rawMessage.createdAt).toISOString(),
      updatedAt: getMoment(rawMessage.updatedAt).toISOString(),
    };
    // If we receive a message in the current channel open, we should mark it as read
    // only if the document is visible (hence `maybe` it will be mark as read)
    props.actions.messages.addAndMaybeRead(fixedRawMessage);
  });

  Socket.on('bulkMessagingSent', ({ bulkMessaging, lastSent }) => {
    props.actions.bulkMessagings.add({
      ...bulkMessaging,
      lastSent,
    });
  });

  Socket.on('bulkMessagingError', ({ bulkMessaging, lastSent }) => {
    props.actions.bulkMessagings.add({
      ...bulkMessaging,
      lastSent,
    });
  });

  Socket.on('userOnline', ({ user }) => {
    props.actions.users.set(user);
  });

  Socket.on('userOffline', ({ user }) => {
    props.actions.users.set(user);
  });

  Socket.on('typing', ({ channel, status, user }) => {
    if (status) {
      debounceTypingActive(props.actions, channel, user, true);
      debounceTypingInactive(props.actions, channel, user, false);
    } else {
      props.actions.users.typing(channel, user, false);
    }
  });

  Socket.on('newEmployee', ({ employee }) => {
    props.actions.employees.add(employee._id, employee);
  });

  Socket.on('employeeUpdated', async ({ employee }) => {
    props.actions.employees.modify(employee._id, employee);
  });

  Socket.on(['newCompany', 'employeeUnblocked', 'companyUnblocked'], ({ company }) => {
    props.actions.companies.set(company);
  });

  Socket.on(['companyBlocked'], async data => {
    props.actions.companies.remove(data.company._id);

    if (props.authCompany._id === data.company._id) {
      props.actions.auth.logout();
    }
  });

  Socket.on(['userBlocked', 'userDeleted'], data => {
    if (props.authEmployee.user_id === data.user._id) {
      props.actions.auth.logout();
    }
  });

  Socket.on(['employeeDeleted', 'employeeBlocked'], async data => {
    if (data.employee.user._id === props.authEmployee.user_id) {
      props.actions.companies.remove(data.company._id);
    }

    if (props.authEmployee._id === data.employee._id) {
      props.actions.auth.logout();
    }
  });

  Socket.on('companyDeleted', async data => {
    props.actions.companies.remove(data.company._id);
    if (props.authCompany._id === data.company._id) {
      props.actions.auth.logout();
    }
  });

  Socket.on('companyUpdated', data => {
    if (props.authCompany._id === data.company) {
      props.actions.auth.user();
    }
  });

  Socket.on(['newChannel', 'channelUpdated'], ({ channel }) => {
    if (channel._id !== props.authEmployee.user._id) {
      props.actions.channels.set({
        ...channel,
        isMember: Object.prototype.hasOwnProperty.call(channel, 'isMember')
          ? channel.isMember
          : true,
      });
    }
  });

  Socket.on(['channelClosed'], ({ channel }) => {
    if (channel._id !== props.authEmployee.user._id) {
      props.actions.channels.set({ ...channel, isMember: false });
    }
  });

  Socket.on('joinChannel', ({ user, channel, status }) => {
    const channelId = props.channels[channel]?._id || user?._id;

    // eslint-disable-next-line camelcase
    if (status && props.authEmployee?.user_id !== user?._id) {
      props.actions.channels.watch(channelId);
      props.actions.messages.markAsRead(channelId);
    }
  });

  Socket.on('deleteMessage', ({ message }) => {
    props.actions.messages.destroyFulfilled(message);
  });

  Socket.on('readMessage', payload => {
    props.actions.messages.onReadMessage(payload);
  });

  Socket.on('checkSession', () => {
    props.actions.auth.verify();
  });

  Socket.on('importFinished', payload => {
    props.actions.employeeImports.finished(payload);
  });

  Socket.on('importDryRunFinished', payload => {
    props.actions.employees.importDryRunFinished(payload);
  });

  return Socket;
};

export default initializeSockets;
